import UserService from "./UserService";
import SettingsService from "../settings/SettingsService";

export default class UserManagementCtrl {
    static $resolve = {
        //users: (UserService: UserService) => UserService.listUsers(),
        userLimit: (SettingsService: SettingsService) => SettingsService.getProfile().then(p => p.tenant.userLimit),
    };

    public err;
    public r;
    public users;
    public profile;
    public planningUsers

    constructor(private DialogService, private UserService: UserService, private SettingsService: SettingsService, public userLimit) {
        this.load()
    }

    async load() {
        this.users = await this.UserService.listUsers()
        this.profile = await this.SettingsService.getProfile();

        this.planningUsers = this.users.filter(u => u.planningAllowed)
    }

    async deleteUser(user) {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Remove " + user.name + " from your team? User will be disabled for a quarantine period, then deleted.");
        if (result) {
            user.deletePending = true;
            try {
                this.r = await this.UserService.deleteUser(user.id);
            } catch (e) {
                this.err = e.data.message;
                user.deletePending = false;
            }
            this.users = await this.UserService.listUsers();
        }
    }

    async enableUser(user) {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Re-enable user " + user.name + " ?");
        if (result) {
            user.enablePending = true;
            try {
                this.r = await this.UserService.enableUser(user.id);
            } catch (e) {
                this.err = e.data.message;
                user.enablePending = false;
            }
            this.users = await this.UserService.listUsers();
        }
    }

    async resetPassword(user) {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Reset password and resend invitaion mail for user " + user.name + " ?");
        if (result) {
            user.resetPending = true;
            try {
                this.r = await this.UserService.resetPassword(user.id);
            } catch (e) {
                this.err = e.data.message;
                user.resetPending = false;
            }
            this.users = await this.UserService.listUsers();
        }
    }


    async disablePlanning(user) {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Disable planning functionality for user " + user.name + " ?\nThis will remove all user permissions for planning instance types.");
        if (result) {
            user.disablePlanningPending = true;
            try {
                this.r = await this.UserService.disablePlanningUser(user.id);
            } catch (e) {
                this.err = e.data.message;
                user.disablePlanningPending = false;
            }
            this.users = await this.UserService.listUsers();
            this.planningUsers = this.users.filter(u => u.planningAllowed)
        }
    }


    async enablePlanning(user) {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Enable planning functionality for user " + user.name + " ?");
        if (result) {
            user.enablePlanningPending = true;
            try {
                this.r = await this.UserService.enablePlanningUser(user.id);
            } catch (e) {
                this.err = e.data.message;
                user.enablePlanningPending = false;
            }
            this.users = await this.UserService.listUsers();
            this.planningUsers = this.users.filter(u => u.planningAllowed)
        }
    }


}
