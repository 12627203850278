import * as angular from "angular";
import uirouter from 'angular-ui-router';
import auth from "./web/auth";
import api from "./web/api";
import layout from "./web/layout";
import apps from "./web/apps";
import workspace from "./web/workspace";
import templates from "./web/templates";
import settings from "./web/settings";
import static_pages from "./web/static";
import util from "./web/util";
import dialogs from "./web/dialogs";
import subscription from "./web/subscription";
import users from "./web/users";
import scheduler from "./web/admin/scheduler";
import usersAdmin from "./web/admin/users";
import appsAdmin from "./web/admin/apps";
import formsAdmin from "./web/admin/forms";
import tenantsAdmin from "./web/admin/tenants";
import messagesAdmin from "./web/admin/messages";
import * as aa from "angular-auth0";
import errors from "./web/errors";
import billing from "./web/billing";
import messages from "./web/messages"
import licensesPartners from "./web/licenses/partners"
import licenses from "./web/licenses/full"
import licensesSuite from "./web/licenses/suite"
import forms from "./web/forms"
import login from "./web/login"
import "lightbox2";
import "lightbox2/dist/css/lightbox.min.css";
import "angular-file-upload";
import "angular-utils-disqus";

import "cookieconsent/build/cookieconsent.min.js";
import "cookieconsent/build/cookieconsent.min.css";

import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import "@fontsource/oswald";

import "./web/vendor/limitless/layout_1/LTR/default/seed/assets/css/bootstrap.min.css";
import "./web/vendor/limitless/layout_1/LTR/default/seed/assets/css/icons/icomoon/styles.css";
import "./web/vendor/limitless/layout_1/LTR/default/seed/assets/css/bootstrap_limitless.min.css";
import "./web/vendor/limitless/layout_1/LTR/default/seed/assets/css/layout.min.css";
import "./web/vendor/limitless/layout_1/LTR/default/seed/assets/css/components.min.css";
import "./web/vendor/limitless/layout_1/LTR/default/seed/assets/css/colors.min.css";
import "./web/vendor/limitless/layout_1/LTR/default/seed/assets/css/icons/material/icons.css";

import "./web/collapsible";
import "./web/app";

const config = window["inphinity-config"];

angular
    .module('inphinity.app.frontend', [uirouter, workspace, auth, api, layout, apps, templates, users, settings, static_pages, billing, util, dialogs, subscription, aa, errors, scheduler, usersAdmin, appsAdmin, messagesAdmin, messages, licensesPartners, licenses, forms, formsAdmin, tenantsAdmin, "angularFileUpload", "angularUtils.directives.dirDisqus", licensesSuite, login])
    .config(function ($urlRouterProvider, angularAuth0Provider, $transitionsProvider) {
        $urlRouterProvider.otherwise(config.home || 'store?sort=-creationDate');
        angularAuth0Provider.init({
            clientID: config.auth0.clientId,
            domain: config.auth0.domain,
            responseType: 'token id_token',
            redirectUri: config.auth0.redirectUri,
            scope: 'openid email profile',
            audience: "msp-backend"
        });
    })
    .value("config", config)
    .run(function ($rootScope, AuthService, $locale) {
        $rootScope.auth = AuthService;
        $locale.DATETIME_FORMATS.medium = "yyyy-MM-dd HH:mm:ss";
        $locale.DATETIME_FORMATS.mediumDate = "yyyy-MM-dd";
        $locale.DATETIME_FORMATS.mediumTime = "HH:mm:ss";
    })
    // google tag manager
    .run(function (config, $transitions) {
        var referer = window.location.href;
        if (config.gtm && config.gtm.id) {
            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start':
                        new Date().getTime(), event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0],
                    j: any = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', config.gtm.id);
        }
        if (config.yandex && config.yandex.id) {
            (function (m, e, t, r, i, k, a) {
                m[i] = m[i] || function () {
                    (m[i].a = m[i].a || []).push(arguments)
                };
                m[i].l = 1 * (new Date() as any);
                k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
            })
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

            window["ym"](config.yandex.id, "init", {
                clickmap: true,
                trackLinks: true,
                trackHash: true,
                accurateTrackBounce: true,
                webvisor: true
            });

            $transitions.onSuccess({}, function (transition) {
                window["ym"](config.yandex.id, window.location.href, {referer: referer})
                referer = window.location.href;
            });

        }

        if (config.tawkto && config.tawkto.id) {
            //<!--Start of Tawk.to Script-->
            var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
            (function (i) {
                var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
                s1.async = true;
                s1.src = 'https://embed.tawk.to/' + i + '/default';
                s1.charset = 'UTF-8';
                s1.setAttribute('crossorigin', '*');
                s0.parentNode.insertBefore(s1, s0);
            })(config.tawkto.id);
            //<!--End of Tawk.to Script-->
        }

        if (config.calendly) {
            var css = document.createElement("link");
            css.rel = "stylesheet";
            css.href = "https://calendly.com/assets/external/widget.css";
            document.head.appendChild(css);
            var s = document.createElement("script");
            s.src = "https://assets.calendly.com/assets/external/widget.js";
            s.async = true;
            document.body.appendChild(s);
        }

    })
    //async-await support for AngularJs
    .run(['$window', '$q', ($window, $q) => {
        $window.Promise = $q;
    }])
    // scroll to top
    .run(['$transitions', ($transitions) => {
        $transitions.onSuccess({}, function ($transition) {
            const fromUrl = $transition.$from().url.pattern.split('?')[0];
            const toUrl = $transition.$to().url.pattern.split('?')[0];
            if (fromUrl !== toUrl) {
                console.log("route changed - scroll to top");
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                window["$"]("#navbar-mobile").collapse('hide');
            }
        });
    }])

window["cookieconsent"].initialise({
    "palette": {
        "popup": {
            "background": "#eaf7f7",
            "text": "#5c7291"
        },
        "button": {
            "background": "#56cbdb",
            "text": "#ffffff"
        }
    },
    "theme": "edgeless",
    "content": {
        "message": "We use cookies to give you the best possible experience on our website. By continuing to browse this site, you give consent for cookies to be used. ",
        "link": "See how.",
        "href": "https://myinphinity.com/privacy-policy/"
    }
});
