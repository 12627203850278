import * as angular from "angular";
import "./loader.css";

export default angular.module("inphinity.app.layout", [])
    .run(function ($rootScope) {
        $rootScope.layout = {
            menu: {
                left: {
                    visible: true,
                    toggle: () => $rootScope.layout.menu.left.visible = !$rootScope.layout.menu.left.visible
                }
            }
        }
    })
    .directive("mspMenuLeft", function () {
        return {
            restrict: "E",
            templateUrl: "html/MenuLeft.html",
            replace: true,
            controllerAs: "ctrl",
            controller: function (AuthService, SettingsService) {
                this.AuthService = AuthService;
                this.SettingsService = SettingsService;
            }
        }
    })
    .directive("mspMenuTop", function () {
        return {
            restrict: "E",
            templateUrl: "html/MenuTop.html",
            controllerAs: "ctrl",
            scope: {},
            controller: function (AuthService, SettingsService, $scope, config) {
                this.AuthService = AuthService;
                this.SettingsService = SettingsService;
                this.config = config
            }
        }
    })
    .directive("mspCard", function () {
        return {
            restrict: "E",
            transclude: true,
            scope: {
                title: "@cardTitle",
                onReload: "&?",
                reloadWorker: "=?",
                onCollapse: "&?",
                onClose: "&?"
            },
            template: require("./Card.html").default
        }
    })
    .directive("workspaceCard", function () {
        return {
            restrict: "E",
            transclude: true,
            scope: {
                app: "=",
                onFavourite: "&?",
                onOpen: "&?",
                onReload: "&?",
                onDownload: "&?",
                onDownloadLogin: "&?",
                onShare: "&?",
                onLoggedIn: "&?",
                onActivate: "&?",
                onActivateLogin: "&?",
                reloadWorker: "=?"
            },
            templateUrl: "html/WorkspaceCard.html"
        }
    })
    .directive("mspPageHeader", function () {
        return {
            restrict: "E",
            transclude: true,
            scope: {
                title: "@",
                subtitle: "@",
                icon: "@"
            },
            templateUrl: "html/PageHeader.html"
        }
    })
    .directive("mspSearchBar", function () {
        return {
            restrict: "E",
            transclude: true,
            scope: {
                title: "@",
                subtitle: "@",
                icon: "@"
            },
            templateUrl: "html/SearchBar.html"
        }
    })
    .directive("mspActionButton", function () {
        return {
            restrict: "E",
            transclude: true,
            scope: {
                "worker": "=",
                "btnStyle": "@",
                disabled: '=ngDisabled'
            },
            template: require("./ActionButton.html").default,
            controller: function () {
            },
            controllerAs: "ctrl"
        }
    })
    .directive("mspLoader", function () {
        return {
            restrict: "E",
            template: require("./Loader.html").default,
        }
    })
    .directive("mspFooter", function () {
        return {
            restrict: "E",
            templateUrl: "html/Footer.html"
        }
    })
    .directive("mspCollapse", function () {
        let collapseId = 0;
        return {
            restrict: "E",
            transclude: true,
            scope: {
                cardTitle: "@cardTitle",
                open: "@?"
            },
            controller: function ($scope, $element) {
                $scope.id = "msp-collapse-" + (collapseId++);
                console.log($scope.id, $scope.open);
                $scope.isOpen = $scope.open !== undefined;
                console.log($scope.id, $scope.isOpen);
            },
            template: require("./Collapse.html").default
        }
    })
    .directive('bindHtmlCompile', ['$compile', function ($compile) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                scope.$watch(function () {
                    return scope.$eval(attrs.bindHtmlCompile);
                }, function (value) {
                    element.html(value);
                    $compile(element.contents())(scope);
                });
            }
        };
    }])
    .name;
