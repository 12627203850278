import {Worker} from "../util";
import UserService from "../users/UserService";
import SettingsService from "../settings/SettingsService";
import SubscriptionService from "../subscription/SubscriptionService";
import FormsService from "../forms/FormsService";
import * as openpgp from "openpgp";
import {COMMENTING_FORM_TYPES, PLANNING_FORM_TYPES, SQL_FORM_TYPES} from "../../util/consts";
import {mssqlEscape} from "../../util/jdbc";
import {changelogString, typesList} from "./FormsCtrl";

const FileSizeLimit = 100 * 1024 * 1024;

export default class FormsSettingsCtrl {
    static $resolve = {
        id: ($stateParams) => $stateParams.id,
        users: (UserService: UserService) => UserService.listUsers().then(list => list.filter(u => !u.disabledAt)),
        permissions: (FormsService: FormsService, $stateParams) => FormsService.getFormPermissions($stateParams.id),
        formGroups: (FormsService: FormsService, $stateParams) => FormsService.getFormGroups($stateParams.id),
        qlikGroups: (UserService: UserService) => UserService.listTenantQlikGroups()
    };

    public userIdMap;
    public groupSelect;
    public assignGroup
    public forms;
    public groups;
    public cleanup = {enableDays: false, enableRecords: false}
    public daysToKeep;
    public recordsToKeep;
    public dbTypeOptions = [
        {label: "MySQL", value: "MYSQL"},
        {label: "MariaDB", value: "MARIADB"},
        {label: "PostgreSQL", value: "PGSQL"},
        {label: "Microsoft SQL Server", value: "MSSQL"},
        {label: "Azure Synapse Analytics", value: "SYNAPSE"},
        {label: "Snowflake", value: "SNOWFLAKE"},
    ];
    public db;
    public dbSettingsDirty = false;
    public testResult;
    public file;
    public err;
    public ok_message;
    public changelogString = changelogString;

    constructor($scope, private $http, private id, private users, private permissions, private qlikGroups, private formGroups, private SettingsService: SettingsService, private SubscriptionService: SubscriptionService, private FormsService: FormsService, private TemplateService, private UserService: UserService, private DialogService, $q, private config) {
        this.updatePermissions = Worker(this.updatePermissions.bind(this));
        this.updateGroups = Worker(this.updateGroups.bind(this));
        this.createToken = Worker(this.createToken.bind(this));
        this.saveDbSettings = Worker(this.saveDbSettings.bind(this));
        this.testDbSettings = Worker(this.testDbSettings.bind(this));
        this.updateCleanupConfig = Worker(this.updateCleanupConfig.bind(this));
        this.uploadXmlFile = Worker(this.uploadXmlFile.bind(this));

        this.init();
    }

    async init() {
        await this.loadForms();
        await this.showUsers();
        await this.showGroups();

    }

    async loadForms() {
        this.forms = await this.FormsService.getForm(this.id);
        this.db = this.forms.config || {};
        this.cleanup.enableDays = this.forms.cleanupDaysToKeep != null;
        this.daysToKeep = this.forms.cleanupDaysToKeep
        this.cleanup.enableRecords = this.forms.cleanupRecordsToKeep != null
        this.recordsToKeep = this.forms.cleanupRecordsToKeep
        if (PLANNING_FORM_TYPES.includes(this.forms.type)) {
            console.log("filter users")
            this.users = this.users.filter(u => u.planningAllowed)
        }
    }

    async showUsers() {
        this.permissions = await this.FormsService.getFormPermissions(this.id);
        this.userIdMap = this.users.reduce((map, user) => {
            map[user.id] = user;
            return map;
        }, {});
        this.users.forEach(user => {
            if (!this.permissions.find(perm => perm.userId == user.id)) {
                this.permissions.push({
                    userId: user.id,
                    formId: this.id,
                    read: false,
                    readWrite: false,
                    moderate: false
                })
            }
        })
    }

    async showGroups() {
        this.formGroups = await this.FormsService.getFormGroups(this.id)
        this.updateGroupSelectBox();
    }

    async updatePermissions() {
        let err;
        try {
            await this.SettingsService.updateFormsPermissions(this.permissions);
        } catch (e) {
            err = e;
        }
        this.showUsers();
        this.loadForms();
        if (err) throw err;
    }

    async updateGroups() {
        let err;
        try {
            await this.FormsService.updateFormGroups(this.id, this.formGroups);
        } catch (e) {
            err = e;
        }
        this.showGroups();
        this.loadForms();
        if (err) throw err;
    }

    async addGroup() {
        if (!this.assignGroup) return;
        this.formGroups.push({
            groupName: this.assignGroup.groupName,
            formId: this.id,
            read: true,
            readWrite: false,
            moderate: false
        })
        this.updateGroupSelectBox();
    }

    async addNewGroup() {
        this.formGroups.push({
            groupName: "",
            formId: this.id,
            read: true,
            readWrite: false,
            moderate: false,
            newGroup: true
        })
    }

    updateGroupSelectBox() {
        this.groupSelect = this.qlikGroups.filter(g => !(this.formGroups.map(fg => fg.groupName).includes(g.groupName)))
    }

    async createToken() {
        let err;
        if (!this.forms.token || await this.DialogService.confirm("Update API token? Current API token will be unusable.")) {
            try {
                await this.FormsService.createToken(this.id);
            } catch (e) {
                err = e;
            }
            this.showUsers();
            this.loadForms();
            if (err) throw err;
        }
    }

    async saveDbSettings() {
        this.testResult = false;
        let result = await this.FormsService.setDatabaseConfig(this.id, {
            ...this.db,
            password: undefined,
            encryptedPassword: this.db.password ? await this.encryptPassword(this.db.type == 'MSSQL' ? mssqlEscape(this.db.password) : this.db.password) : undefined
        });
        this.dbSettingsDirty = false;
        return result;
    }

    async testDbSettings() {
        this.testResult = await this.FormsService.testForm(this.id);
    }

    async encryptPassword(password: string): Promise<string> {
        let keychain: openpgp.Key;
        try {
            let key = await this.FormsService.getFormPublicKey(this.id);
            keychain = await openpgp.readKey({armoredKey: key});
        } catch (e) {
            throw new Error("Failed to load public key for database credentials encryption");
        }
        let encrypted = await openpgp.encrypt({
            message: await openpgp.createMessage({text: password}),
            encryptionKeys: keychain
        })
        return encrypted.toString();
    }

    isDatabase() {
        return SQL_FORM_TYPES.includes(this.forms?.type);
    }

    isCommenting() {
        return COMMENTING_FORM_TYPES.includes(this.forms?.type);
    }

    async updateCleanupConfig() {
        let result = await this.FormsService.updateCleanupConfig(this.id, {
            daysToKeep: this.cleanup.enableDays ? this.daysToKeep : null,
            recordsToKeep: this.cleanup.enableRecords ? this.recordsToKeep : null
        });
        return result;
    }

    validCleanupValues() {
        return (this.cleanup.enableDays == false || (this.cleanup.enableDays == true && this.daysToKeep != null && this.daysToKeep >= 0))
            && (this.cleanup.enableRecords == false || (this.cleanup.enableRecords == true && this.recordsToKeep != null && this.recordsToKeep >= 0))
    }

    handleFile(event) {
        this.ok_message = undefined;
        this.err = undefined;
        this.file = event.target.files[0];
        if (this.file) {
            if (this.file.size > FileSizeLimit) {
                this.err = "File size is too large."
            }
        }
    }

    async uploadXmlFile() {
        this.err = undefined;
        this.ok_message = undefined;
        let result = await this.DialogService.confirm("Upload " + this.file.name + " to this form instance? Actual form data will be replaced.");
        if (result) {
            try {
                let result = await this.FormsService.importData(this.id, this.file);
                if (result) {
                    this.ok_message = "XML upload successfully."
                }
            } catch (e) {
                if (e.data.message) {
                    this.err = e.data.message;
                } else {
                    this.err = "Failed to import XMl file -> status: " + e.status + " - " + e.statusText;
                }
            }
            await this.loadForms();
        }
    }

    isFileValid() {
        return this.file !== undefined && this.file.size < FileSizeLimit;
    }

    getFormTypeString(type) {
        return typesList[type]
    }

}
